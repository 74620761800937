import { configureStore } from '@reduxjs/toolkit';
import { EqualityFn, useSelector } from 'react-redux';
import { products, getProducts } from './products.redux';

export const store = configureStore({
  reducer: {
    products: products.reducer,
  },
});

export const actions = {
  ...products.actions,
};

export const thunks = {
  getProducts,
};

export type GcStoreState = ReturnType<typeof store.getState>;
export type GcDispatch = typeof store.dispatch;

export const useGcSelector: <Selected = unknown>(
  selector: (state: GcStoreState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined,
) => Selected = useSelector;
