import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles({ name: 'GcProductCard' })(
  (theme) => ({
    param: {
      color: theme.gc.colors.nutrientValue,
      flex: 1,
      marginRight: theme.gc.card.spacing,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
);
