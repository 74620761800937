import { theme } from 'core/theme';
import { CSSInterpolation } from 'tss-react';

export const globalStyles: CSSInterpolation = {
  html: {
    [theme.breakpoints.up('mobile')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('bigDesktop')]: {
      fontSize: 18,
    },
  },
  body: {
    margin: 0,
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif`,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  code: {
    fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`,
  },
};
