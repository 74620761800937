export const gcConfig = {
  uiApi: process.env.REACT_APP_API || '',
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN || '',
  env: (process.env.REACT_APP_ENV || '') as 'dev' | 'prod',
};

if (process.env.NODE_ENV !== 'production') {
  const missingVars = Object.entries(gcConfig).reduce(
    (result, [key, value]) => {
      if (!value) {
        result.push(key);
      }
      return result;
    },
    [] as string[],
  );

  if (missingVars.length) {
    throw new Error(
      `👉 The next environment variables are missing: ${missingVars.join(
        ', ',
      )}\n`,
    );
  }
}
