import URLSearchParams from '@ungap/url-search-params';
import { MapLike } from 'typescript';
import { gcConfig } from './config';
export interface IdTokenPayload extends MapLike<unknown> {
  email: string;
  exp: number;
}

interface OAuth2TokenResponse {
  error?: string;
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  token_type?: string;
}

const lsRefreshToken = 'auth_refresh_token';
const lsIdToken = 'auth_id_token';
const lsPartnerId = 'selected-partner';

class Auth {
  public authorized = false;
  public verifyingOnLogin = false;

  private config = {
    clientId: gcConfig.cognitoClientId,
    domain: gcConfig.cognitoDomain,
    redirectLogin: '/loggedIn',
    redirectLogout: '/loggedOut',
    responseType: 'code',
  };
  private token = '';
  private email = '';
  private exp = 0;

  constructor() {
    this.initFromStorage();
    const currentPath = window.location.pathname;

    if (currentPath === this.config.redirectLogin) {
      const code = new URLSearchParams(window.location.search).get('code');
      this.verifyingOnLogin = true;
      this.getTokensFromCode(code || '')
        .then((payload) => {
          if (payload.error) {
            throw new Error(payload.error);
          } else {
            this.saveTokensToStorage(payload);
          }
        })
        .finally(() => {
          this.verifyingOnLogin = false;
          window.location.replace(window.location.origin);
        });
      return;
    }

    if (currentPath === this.config.redirectLogout) {
      this.clearStorage();
      window.location.replace(window.location.origin);
      return;
    }
  }

  public login = (): void => {
    window.location.assign(this.getLoginUrl());
  };

  public logout = (): void => {
    window.location.assign(this.getLogoutUrl());
  };

  public getEmail = (): string => this.email;

  public getToken = async (): Promise<string> => {
    if (!this.token) {
      return '';
    }

    if (Date.now() < this.exp * 1000) {
      return this.token;
    } else {
      const refreshToken = localStorage.getItem(lsRefreshToken);

      if (refreshToken) {
        try {
          const payload = await this.getRefreshedTokens(refreshToken);

          if (!payload.error) {
            this.saveTokensToStorage(payload);
          } else {
            console.error(payload.error);
            this.clearStorage();
          }
        } catch (e) {
          // In case of network problems, we just skip until next time
          console.error(e);
          return this.token;
        }
      } else {
        this.clearStorage();
      }
      this.initFromStorage();
      return this.token;
    }
  };

  private initFromStorage = (): void => {
    this.exp = 0;
    this.authorized = false;
    this.token = localStorage.getItem(lsIdToken) || '';

    try {
      if (this.token) {
        const { exp, email } = this.getTokenPayload(this.token);
        this.exp = exp;
        this.email = email;
        this.authorized = true;
      }
    } catch (e) {
      console.error(e);
      this.token = '';
    }
  };

  private saveTokensToStorage = ({
    id_token,
    // access_token,
    refresh_token,
  }: OAuth2TokenResponse): void => {
    localStorage.setItem(lsIdToken, id_token || '');
    // localStorage.setItem(lsAccessToken, access_token || '');
    // we have refresh token only after login request
    if (refresh_token) {
      localStorage.setItem(lsRefreshToken, refresh_token);
    }
  };

  private clearStorage = (): void => {
    localStorage.setItem(lsIdToken, '');
    // localStorage.setItem(lsAccessToken, '');
    localStorage.setItem(lsRefreshToken, '');
    localStorage.setItem(lsPartnerId, '');
  };

  private getAuthUrl = (
    path: string,
    params: MapLike<unknown>,
  ): string => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchParams.set(key, (params as any)[key]),
    );
    return `https://${
      this.config.domain
    }/${path}?${searchParams.toString()}`;
  };

  private getLoginUrl = (): string =>
    this.getAuthUrl('login', {
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      response_type: this.config.responseType,
    });

  private getLogoutUrl = (): string =>
    this.getAuthUrl('logout', {
      client_id: this.config.clientId,
      logout_uri: window.location.origin + this.config.redirectLogout,
    });

  private getTokenPayload: (token: string) => IdTokenPayload = (token) => {
    const payloadPart = token.split('.')[1];
    if (payloadPart) {
      return JSON.parse(
        window.atob(payloadPart.replace(/-/g, '+').replace(/_/g, '/')),
      );
    } else {
      throw new Error('Invalid token');
    }
  };

  private fetchUrlEncoded = (
    path: string,
    params: MapLike<unknown>,
  ): Promise<Response> => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchParams.set(key, (params as any)[key]),
    );

    return fetch(`https://${this.config.domain}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    });
  };

  private getTokensFromCode: (
    code: string,
  ) => Promise<OAuth2TokenResponse> = (code) =>
    this.fetchUrlEncoded(`oauth2/token`, {
      grant_type: 'authorization_code',
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      code,
    }).then((response) => response.json());

  private getRefreshedTokens: (
    refreshToken: string,
  ) => Promise<OAuth2TokenResponse> = (refreshToken) =>
    this.fetchUrlEncoded(`oauth2/token`, {
      grant_type: 'refresh_token',
      client_id: this.config.clientId,
      redirect_uri: window.location.origin + this.config.redirectLogin,
      refresh_token: refreshToken,
    }).then((response) => response.json());
}

const auth = new Auth();

export default auth;
