import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GC_ENTITY, GC_THUNK_ACTION } from 'core/const';
import { GcProduct } from '../types';
import { api } from './api';

export const getProducts = createAsyncThunk<GcProduct[]>(
  `${GC_ENTITY.PRODUCT}/${GC_THUNK_ACTION.GET}`,
  async () => {
    try {
      return await api.getProducts();
    } catch (error) {
      return [];
    }
  },
);

export const products = createSlice<
  GcProduct[],
  Record<string, never>,
  GC_ENTITY.PRODUCT
>({
  name: GC_ENTITY.PRODUCT,
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (_, action) => {
      return action.payload;
    });
  },
});
