import { Box, Paper } from '@mui/material';
import { GcProduct } from 'core/types';
import { useStyles } from './GcProductCard.styles';

export const GcProductCard: React.FC<GcProduct> = ({
  attributes: { name, calories, protein, fat, carbs },
}) => {
  const { classes, theme } = useStyles();

  return (
    <Paper
      sx={{
        minWidth: theme.gc.card.width,
        maxWidth: theme.gc.card.width,
        minHeight: theme.gc.card.height,
        maxHeight: theme.gc.card.height,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      elevation={3}
    >
      <Box
        sx={{
          padding: theme.gc.card.spacing,
        }}
      >
        {name}
      </Box>
      <Box
        sx={{
          flex: 1,
          bgcolor: '#ccc',
        }}
      >
        Image
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: theme.gc.card.spacing,
        }}
      >
        <Box
          sx={{ bgcolor: theme.gc.colors.protein }}
          className={classes.param}
        >
          {protein}
        </Box>
        <Box
          sx={{ bgcolor: theme.gc.colors.fat }}
          className={classes.param}
        >
          {fat}
        </Box>
        <Box
          sx={{ bgcolor: theme.gc.colors.carbs }}
          className={classes.param}
        >
          {carbs}
        </Box>
        <Box
          sx={{ bgcolor: theme.gc.colors.protein }}
          className={classes.param}
        >
          {calories}
        </Box>
      </Box>
    </Paper>
  );
};
