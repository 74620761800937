import { Box } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import auth from 'core/auth';
import { thunks } from 'core/redux';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GlobalStyles } from 'tss-react';
import { GcMainBar } from './GcAppBar';
import { GcDay } from './GcDay';
import { GcDayTopBar } from './GcDayTopBar';
import { globalStyles } from './GcRoot.styles';

const GcRootRaw: React.FC<unknown> = () => {
  const d = useDispatch();

  useEffect(() => {
    console.log('authorized: ', auth.getEmail());
    if (auth.authorized) {
      d(thunks.getProducts() as unknown as AnyAction);
    }
  }, []);

  return (
    <Box>
      <GlobalStyles styles={globalStyles} />
      <GcMainBar />
      <Box>
        <GcDayTopBar />
        <GcDay />
      </Box>
    </Box>
  );
};

export const GcRoot = memo(GcRootRaw);
