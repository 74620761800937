import { CssBaseline, ThemeProvider } from '@mui/material';
import { store } from 'core/redux';
import { theme } from 'core/theme';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { GcRoot } from './GcRoot';

export const GcApp: React.FC = () => {
  return (
    <StrictMode>
      <CssBaseline />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GcRoot />
        </ThemeProvider>
      </Provider>
    </StrictMode>
  );
};
