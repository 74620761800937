import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, useTheme, Zoom } from '@mui/material';
import { useGcSelector } from 'core/redux';
import { GcProductCard } from './GcProductCard';

export const GcDay: React.FC = () => {
  const theme = useTheme();

  const products = useGcSelector((s) => s.products);

  return (
    <Box
      sx={{
        bgcolor: '#cbcbb1',
        margin: `0 ${theme.spacing(1)}`,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, ${theme.gc.card.width})`,
          gridGap: theme.spacing(1),
          justifyContent: 'center',
        }}
      >
        {products.map((product) => {
          return <GcProductCard key={product.id} {...product} />;
        })}
      </Box>

      <Zoom in={true} unmountOnExit>
        <Fab
          color="primary"
          sx={{
            color: 'common.white',
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
          }}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Box>
  );
};
