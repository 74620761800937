import { createTheme } from '@mui/material';

const spacingCoefficient = 0.6667;

const customThemeParams = {
  colors: {
    protein: '#f28585',
    fat: '#e4c463',
    carbs: '#7caa7e',
    calories: '#999999',
    nutrientValue: '#fff',
  },
  card: {
    width: `${12}rem`,
    height: `${9}rem`,
    bgColor: `gray`,
    spacing: `${spacingCoefficient * 0.5}rem`,
  },
};

type CustomThemeParams = typeof customThemeParams;

export const theme = createTheme({
  gc: customThemeParams,
  spacing: (factor: number) => `${spacingCoefficient * factor}rem`,
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 620,
      laptop: 1024,
      desktop: 1200,
      bigDesktop: 2000,
    },
  },
});

declare module '@mui/material/styles' {
  interface ThemeOptions {
    gc: CustomThemeParams;
  }
  interface Theme {
    gc: CustomThemeParams;
  }
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
    bigDesktop: true;
  }
}
