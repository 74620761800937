import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import auth from 'core/auth';

export const GcMainBar: React.FC = () => {
  return (
    <AppBar position="static">
      <Toolbar sx={{ pl: 1, pr: 0.5 }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Gray Cat
        </Typography>
        {auth.authorized ? (
          <Button color="inherit" onClick={auth.logout}>
            Logout
          </Button>
        ) : (
          <Button color="inherit" onClick={auth.login}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
