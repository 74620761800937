import auth from 'core/auth';
import { gcConfig } from 'core/config';
import { GcProduct } from 'core/types';

async function formHeaders(): Promise<Record<string, string>> {
  const token = await auth.getToken();
  const headers: Record<string, string> = {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return headers;
}

async function apiCall({ route }: { route: string }): Promise<Response> {
  return fetch(`${gcConfig.uiApi}/v1/${route}`, {
    method: 'GET',
    headers: await formHeaders(),
  });
}

class GcApi {
  public async getProducts(): Promise<GcProduct[]> {
    const res = await apiCall({ route: 'product' });
    const { data } = await res.json();
    return data as GcProduct[];
  }
}

export const api = new GcApi();
